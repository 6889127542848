import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Default from './ui/views/Default'
import Expired from './ui/views/Expired'
import Invalid from './ui/views/Invalid'
import QuizFailed from './ui/views/QuizFailed'
import QuizTimeout from './ui/views/QuizTimeout'
import QuizFailedBlocked from './ui/views/QuizFailedBlocked'
import Confirmation from './ui/views/Confirmation'
import Success from './ui/views/Success'
import Container from './ui/theme/styles'

function App() {
  return (
    <Router>
      <Container>
        <div className="main_container">
          <table className="main_table">
            <thead>
              <tr>
                <td align="center">
                  <img src="https://static-images-inovamind.s3.us-east-1.amazonaws.com/sa/privacidade-logo.png"
                    className=".image" alt="logo-sula-lgpd" />
                </td>
              </tr>
            </thead>
            <tbody>
              <tr><td style={{ "padding": "18px 50px 40px" }}></td></tr>
              <tr>
                <td className='text_item'>
                  <Switch>
                    <Route exact path='/' component={Default} />
                    <Route path='/confirmation' component={Confirmation} />
                    <Route path='/expired' component={Expired} />
                    <Route path='/invalid' component={Invalid} />
                    <Route path='/quiz-failed' component={QuizFailed} />
                    <Route path='/quiz-timeout' component={QuizTimeout} />
                    <Route path='/quiz-failed-blocked' component={QuizFailedBlocked} />
                    <Route path='/success' component={Success} />
                    <Route component={Default} />
                  </Switch>
                </td>
              </tr>
              <tr><td style={{ "padding": "18px 50px 40px" }}></td></tr>
            </tbody>
            <tfoot>
              <tr>
                <td align="center">
                  <img src="https://static-images-inovamind.s3.us-east-1.amazonaws.com/sa/logo.png" alt="logo-sula" />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </Container>
    </Router>
  );
}

export default App;
