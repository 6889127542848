import styled from "styled-components"

export default styled.div`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    border: 6px solid #383636; /* Black */
    border-top: 6px solid #f3f3f3; /* Light grey */
    border-radius: 50%;
    animation: spinner 0.6s linear infinite;
  }
`