import styled from 'styled-components'


export default styled.div`
    font-family: monospace;

    .main_container{
        padding: 1em 0;
        background-color:
        #f4f4f42b;
        border-radius: 5px;
        margin: 4em auto;
    }
    
    .image{
        width: 200px;
        height: 100px;
    }

    .main_table{
        width:100%;
    }

    .text_item{
        font-size: 20px;
        color: #f47521;
        font-weight: bold;
        padding: 25px 50px 30px;
    }

    @media only screen and (max-width: 48em) {
        .main_container{
            width:30em;
        }
    }

    @media only (min-width: 48em) and (max-width: 64em) {
        .main_container{
            width:50em;
        }
    }

    @media only (min-width: 64em) and (max-width: 85.375em) {
        .main_container{
            width:70.375;
        }
    }

    @media only (min-width: 85.375em) {
        .main_container{
            width:100em;
        }
    }
`
