import serviceUrl from '../config'

import axios from './axiosCustom'

export const get = async ({ url, token, responseType }) => {
  return await service('GET', url, token, null)
}

export const post = async ({ url, token, data }) => {
  return await service('POST', url, token, data, null)
}

export const apiPut = async ({ url, token, data }) => {
  return await service('PUT', url, token, data, null)
}

export const apiDelete = async ({ url, token }) => {
  return await service('delete', url, token, null)
}

const service = async (method, url, token, data = null, responseType = null) => {
  try {

    console.log(`${serviceUrl[process.env.NODE_ENV]}${url}`)
    const res = await axios({
      method: method,
      url: `${serviceUrl[process.env.NODE_ENV]}${url}`,
      headers: { 'Content-Type': 'application/json' },
      data,
      responseType: responseType || null
    })
    return {
      status: res.status,
      data: res.data,
    }
  } catch (e) {
    if (e.response) {
      return {
        status: e.response.status,
        message: e.response.data.message,
        token: e.response.data.token,
        data: e.response.data
      }
    } else {
      return {
        message: 'Houve um problema na requisicao',
      }
    }
  }
}