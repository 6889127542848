import React from 'react'
import Container from '../../theme/styles'
export default function ({ history }) {

    return (<Container>
        <div className="content">
            <h3>Link quebrado</h3>
            Por favor certifique-se que o link accessado está correto
        </div>
    </Container >)
} 