import React from "react";

import Container from "./styles";

function Spinner({ size, color, bgColor }) {
  return (
    <Container>
      <div
        style={{
          width: size || '50px',
          height: size|| '50px',
          borderColor: color || "#212121",
          borderTopColor: bgColor || "#ffffff",
        }}
        className="loading-spinner"
      />
    </Container>
  );
}

export default Spinner;