import React from 'react'
import Container from '../../theme/styles'
export default function ({ history }) {

    return (<Container>
        <div className="content">
            <h3>Ops, esse link expirou pois excedeu os 5 minutos de tolerância.</h3>

            Retorne ao Portal de Privacidade da SulAmérica e refaça a solicitação de cadastro.
        </div >
    </Container >)
} 