import React from 'react'
import Container from '../../theme/styles'

export default function ({ history }) {

    return (<Container>
        <div className="content">
            <h3>Autenticação realizada com sucesso!</h3>

            Retorne ao Portal de Privacidade e continue o seu cadastro.
        </div>
    </Container>)
} 