import React from 'react'
import Container from '../../theme/styles'
export default function ({ history }) {

    return (<Container>
        <div className="content">
            <h3>Sua autenticação foi rejeitada pois você não respondeu ao Quiz corretamente.</h3>

            Aguarde 24 horas para tentar novamente.
        </div>
    </Container>)
}