import React, { useEffect } from "react"
import { useCallback } from "react"
import { get } from '../../../service/api'
import Spinner from '../../components/Spinner'

function Confirmation({ location }) {

  const confirmToken = useCallback(async () => {
    const queryParams = new URLSearchParams(location.search)
    const token = queryParams.get("token")
    const action = queryParams.get("do")
    const status = queryParams.get("status")
    
    let url = `/sacvalidation/v1/confirmation?token=${token}`
    
    if(action) {
      url += `&do=${action}`
    }

    if(status) {
      url += `&status=${status}`
    }

    const response = await get({ url })
    
    if(response.status === 200) {
      window.location.replace(response?.data?.headers?.Location)
    }
  }, [location.search])

  useEffect(() => {
    confirmToken()
  }, [confirmToken])

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <Spinner color='#f47521' />
      </div>
    </>
  )
}

export default Confirmation